// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arsredovisning-js": () => import("./../../../src/pages/arsredovisning.js" /* webpackChunkName: "component---src-pages-arsredovisning-js" */),
  "component---src-pages-auktoriserade-redovisningskonsult-js": () => import("./../../../src/pages/auktoriserade-redovisningskonsult.js" /* webpackChunkName: "component---src-pages-auktoriserade-redovisningskonsult-js" */),
  "component---src-pages-bokforing-js": () => import("./../../../src/pages/bokforing.js" /* webpackChunkName: "component---src-pages-bokforing-js" */),
  "component---src-pages-bokslut-js": () => import("./../../../src/pages/bokslut.js" /* webpackChunkName: "component---src-pages-bokslut-js" */),
  "component---src-pages-controllerfunktion-js": () => import("./../../../src/pages/controllerfunktion.js" /* webpackChunkName: "component---src-pages-controllerfunktion-js" */),
  "component---src-pages-deklarationer-js": () => import("./../../../src/pages/deklarationer.js" /* webpackChunkName: "component---src-pages-deklarationer-js" */),
  "component---src-pages-delgivningsmottagare-js": () => import("./../../../src/pages/delgivningsmottagare.js" /* webpackChunkName: "component---src-pages-delgivningsmottagare-js" */),
  "component---src-pages-fakturering-js": () => import("./../../../src/pages/fakturering.js" /* webpackChunkName: "component---src-pages-fakturering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integritet-och-policy-js": () => import("./../../../src/pages/integritet-och-policy.js" /* webpackChunkName: "component---src-pages-integritet-och-policy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-loneadministration-js": () => import("./../../../src/pages/loneadministration.js" /* webpackChunkName: "component---src-pages-loneadministration-js" */),
  "component---src-pages-nyhet-js": () => import("./../../../src/pages/nyhet.js" /* webpackChunkName: "component---src-pages-nyhet-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-radgivning-js": () => import("./../../../src/pages/radgivning.js" /* webpackChunkName: "component---src-pages-radgivning-js" */),
  "component---src-pages-starta-bolag-js": () => import("./../../../src/pages/starta-bolag.js" /* webpackChunkName: "component---src-pages-starta-bolag-js" */)
}

